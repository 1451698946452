import React, { useEffect } from 'react'
import MessageTemplate from '../components/MessageTemplate'
import { messages } from '../constant'

const Messages = () => {
  useEffect(() => {
    document.title = 'Messages - Greentouch Group'
  }, [])
  return (
    <div>
      {messages.map((message) => (
        <MessageTemplate
          title={message.title}
          detail={message.detail}
          style={message.style}
          image={message.image}
          img_alt={message.img_alt}
          name={message.name}
          designation={message.designation}
          company={message.company}
        />
      ))}
    </div>
  )
}

export default Messages
