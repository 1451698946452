import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { officeAddress } from '../constant'

const ContactUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    document.title = 'Contact Us - Greentouch Group'
  }, [])

  const prevMap = () => {
    const isFirstMap = currentIndex === 0
    const newIndex = isFirstMap ? officeAddress.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextMap = () => {
    const isLastMap = currentIndex === officeAddress.length - 1
    const newIndex = isLastMap ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  return (
    <>
      {/* desktop view */}

      <div className='flex flex-col-reverse items-center md:relative'>
        <iframe
          src={officeAddress[currentIndex].mapUrl}
          width='3840'
          // {window.screen.width >= '720px' ? '3840' : '1440'}
          height='2160'
          // {window.screen.width >= '720px' ? '2160' : '2560'}
          // style='border:0;'
          allowfullscreen=''
          loading='lazy'
          referrerpolicy='no-referrer-when-downgrade'
          className='w-full h-screen'
        ></iframe>

        {/* address container is here */}

        <div className='bg-[#188D5E] relative md:absolute w-[22rem] max-h-3/5 min-h-[500px]  md:top-16 my-8 mt-28 md:right-16 2xl:right-60 rounded-2xl shadow-2xl p-4'>
          <div className='absolute bg-white top-4 right-0 flex items-center justify-between w-24 p-2 px-4 rounded-l-full'>
            <button>
              <FaArrowLeft
                className='text-[#188D5E] cursor-pointer'
                onClick={prevMap}
                size={20}
              />
            </button>
            <button>
              <FaArrowRight
                className='text-[#188D5E] cursor-pointer'
                onClick={nextMap}
                size={20}
              />
            </button>
          </div>
          <h1 className='text-white font-bold text-4xl w-full mx-auto text-center mt-12'>
            Greentouch Group
          </h1>
          <h1 className='text-white font-bold text-xl w-full ml-3 mt-6 md:mt-8'>
            {officeAddress[currentIndex].office}
          </h1>
          <div className='mt-6 md:mt-8 ml-6'>
            <h2 className='text-[#D6D6D6]'>Address</h2>
            <p className='text-white'>{officeAddress[currentIndex].address}</p>
          </div>
          <div className='mt-6 md:mt-8 ml-6'>
            <h2 className='text-[#D6D6D6]'>Phone</h2>
            {officeAddress[currentIndex].phone.map((item) => (
              <p className='text-white'>{item}</p>
            ))}
          </div>
          <div className='mt-6 md:mt-8 ml-6'>
            <h2 className='text-[#D6D6D6]'>Email</h2>
            <p className='text-white'>{officeAddress[currentIndex].email}</p>
          </div>
        </div>
      </div>

      {/* mobile view */}
    </>
  )
}

export default ContactUs
