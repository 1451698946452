import React, { useEffect } from 'react'
import Carousel from '../components/Carousel'
import CompanyDetails from '../components/CompanyDetails'
import {
  corporationOverview,
  corporationHistory,
  corporationMission,
  corporationVision,
  gclProjects,
  corporationSlides,
  corporationInfo,
  corporationProduct,
} from '../constant'
import Projects from '../components/Projects'
import Products from '../components/Products'

const GCL = () => {
  useEffect(() => {
    document.title = 'Greentouch Corporation Ltd'
  }, [])

  return (
    <div className='snap-proximity snap-y'>
      <div className='snap-center'>
        <Carousel slides={corporationSlides} />
      </div>
      <div className='snap-center'>
        {corporationInfo.map((info) => (
          <CompanyDetails
            title={info.title}
            detail={info.detail}
            style={info.style}
          />
        ))}
      </div>

      <Products products={corporationProduct} />

      <Projects slides={gclProjects} />
    </div>
  )
}

export default GCL
