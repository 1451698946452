import React from 'react'
import { milestonesInfo } from '../constant'

const Milestone = () => {
  return (
    <div className='relative w-full h-auto md:h-[1550px] min-h-fit px-5 md:px-20 bg-gradient-to-t md:bg-gradient-to-br from-[#EED11A] to-[#84D9E3] to-[75%]'>
      {/* <div className='relative w-full min-h-fit px-5 md:px-20 bg-gradient-to-t md:bg-gradient-to-br from-[#EED11A] to-[#84D9E3] to-[75%]'> */}
      <div className='hidden lg:block'>
        <div className='w-[4px] h-[1050px] top-[310px] left-[50%] -translate-x-[50%] absolute bg-gradient-to-b from-green-600 to-yellow-600 '></div>
        <div className='w-[402px] h-[78px] left-[50%] -translate-x-[50%] top-[120px] absolute bg-gradient-to-b from-green-200 to-yellow-300 flex items-center justify-center text-orange-500 text-[70px] font-bold text-center rounded-2xl shadow'>
          Milestone
        </div>

        {milestonesInfo.map((milestone, index) => (
          <div
            key={index}
            style={{
              '--top-value': `${280 + index * 130}px`,
            }}
            className={`peer/${milestone.year} hover:scale-110 w-[151px] h-[61px] left-[50%] -translate-x-[50%] top-[var(--top-value)] absolute bg-gradient-to-b from-green-200 to-yellow-300 flex items-center justify-center text-black text-[35px] text-center rounded-2xl shadow transition-all`}
          >
            {milestone.year}
          </div>
        ))}

        {milestonesInfo.map((milestone, index) => (
          <div
            key={index}
            style={{
              '--top-value': `${250 + index * 130}px`,
            }}
            className={`peer-hover/${
              milestone.year
            }:scale-110 hover:scale-110 w-[504px] h-[102px] ${
              index % 2 === 0
                ? 'left-[60%] text-left'
                : 'right-[60%] text-right'
            } top-[var(--top-value)] absolute text-black text-[20px] font-normal transition-all`}
          >
            {milestone.description}
          </div>
        ))}
      </div>

      {/* mobile view */}
      <div className='lg:hidden relative flex flex-col items-center justify-center py-5'>
        <div className='w-[200px] bg-gradient-to-b from-green-200 to-yellow-300 flex items-center justify-center text-orange-500 text-[35px] font-bold text-center rounded-2xl shadow-xl'>
          Milestone
        </div>

        {milestonesInfo.map((milestone, index) => (
          <React.Fragment key={index}>
            <div className='w-[80px] bg-gradient-to-b from-green-200 to-yellow-300 flex items-center justify-center mt-8 text-[25px] text-center rounded-xl shadow-xl'>
              {milestone.year}
            </div>
            <div className=' mt-3 text-[18px] text-center font-normal transition-all'>
              {milestone.description}
            </div>
            <div className='w-11/12 h-1 mt-2 bg-gradient-to-l from-green-400 to-yellow-400'></div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default Milestone
