import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GroupDetails = ({ title, detail, style, framerMotionStyle }) => {
  //   const text =
  //     'At Greentouch Group, we are dedicated to providing innovative and sustainable solutions to the construction and RMG industry in Bangladesh. Our group of companies offers a range of comprehensive products and services, including raw materials supply, transportation solutions, real estate development, and apparel sourcing services. We prioritize quality, customer satisfaction, and environmental responsibility in all that we do, and our team of experts is committed to delivering exceptional results. We embrace new technologies and opportunities to stay ahead of the curve, and we are constantly evolving to meet the changing needs of our industry. As we continue to diversify and expand our business, we create long-term value for our customers, stakeholders, and society shaping a better future for everyone.'

  //   const [displayText, setDisplayText] = useState('')
  //   const [currentIndex, setCurrentIndex] = useState(0)

  //   useEffect(() => {
  //     if (currentIndex < text.length) {
  //       const timeout = setTimeout(() => {
  //         setCurrentIndex((prevIndex) => prevIndex + 1)
  //       }, 10)
  //       return () => clearTimeout(timeout)
  //     }
  //   }, [currentIndex, text.length])

  //   useEffect(() => {
  //     setDisplayText(text.substring(0, currentIndex))
  //   }, [currentIndex, text])

  return (
    <div
      className={`${style} items-center justify-center w-full min-h-screen md:h-screen px-5 py-5 md:py-0 md:px-20`}
    >
      <h1 className='text-[40px] md:text-[80px] md:w-1/2 font-bold text-orange-500 text-center mb-4'>
        {title || <Skeleton />}
      </h1>
      {console.log(framerMotionStyle)}
      <motion.div
        whileInView={{ x: [framerMotionStyle, 0] }}
        transition={{ delay: 1 }}
        className='text-[16px] md:text-[20px] leading-[18px] md:leading-[30px] md:w-1/2 md:tracking-wide text-justify'
      >
        {detail || <Skeleton count={5} />}
      </motion.div>
    </div>
  )
}

export default GroupDetails
