import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { logo, menu, close } from '../../assets'
import { navLinks } from '../../constant'
import { FaTimes, FaBars, FaChevronDown } from 'react-icons/fa'

const Navbar = () => {
  const [active, setActive] = useState('Home')
  const [toggle, setToggle] = useState(false)
  const [dropdown, setDropdown] = useState(null)
  const [mobileDropdown, setMobileDropdown] = useState(null)
  const dropdownRef = useRef()

  const toggleDropdown = (navId) => {
    setDropdown(navId === dropdown ? null : navId)
  }
  const toggleMobileDropdown = (navId) => {
    setMobileDropdown(navId === mobileDropdown ? null : navId)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(null)
        // setToggle(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <nav className='fixed top-0 left-0 bg-white/60 w-full flex py-2 px-10 md:px-20 justify-between items-center navbar z-50'>
      <Link to='/'>
        <img src={logo} alt='greentouch logo' className='w-20' />
      </Link>

      <div className='hidden sm:flex justify-end items-center flex-1'>
        <ul className='list-none flex'>
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`flex items-center font-poppins font-normal cursor-pointer text-[20px] ${
                active === nav.title ? 'text-orange-500' : 'text-black'
              } ${index === navLinks.length - 1 ? 'mr-0' : 'mr-10'}`}
              onClick={() => {
                setActive(nav.title)
                toggleDropdown(nav.id)
              }}
            >
              <Link to={`/${nav.id}`}>{nav.title}</Link>
              {nav.dropdown && (
                <FaChevronDown
                  className={`ml-1 mt-0.5 transition-all ${
                    dropdown === nav.id ? 'transform rotate-180' : ''
                  }`}
                  size={15}
                />
              )}
              {nav.dropdown && dropdown === nav.id && (
                <motion.div
                  whileInView={{ x: [300, 0] }}
                  transition={{ duration: 0.85, ease: 'easeOut' }}
                  className='z-50 absolute mt-80 space-y-2 py-2 bg-[#B1E781] border rounded-lg transition-all duration-300'
                  ref={dropdownRef}
                >
                  <ul>
                    {nav.dropdown.map((item) => (
                      <li
                        key={item.id}
                        className='text-black px-4 py-2 cursor-pointer hover:bg-gray-100 hover:text-green-500'
                        onClick={() => setActive(item.title)}
                      >
                        <Link to={`/${item.id}`}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </motion.div>
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className='sm:hidden flex flex-1 justify-end items-center text-orange-500 transition-all'>
        {toggle ? (
          <FaTimes
            size={30}
            onClick={() => setToggle(!toggle)}
            className='z-50 transition-all'
          />
        ) : (
          <FaBars
            size={30}
            onClick={() => setToggle(!toggle)}
            className='transition-all'
          />
        )}

        {toggle && (
          <motion.div
            whileInView={{ x: [500, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
            className='p-6 bg-green-500 text-white fixed inset-0 z-40 flex
            flex-col justify-center items-center transition-all duration-300'
          >
            <ul className='list-none flex flex-col items-center'>
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-normal cursor-pointer text-[20px] ${
                    active === nav.title ? 'text-orange-400' : 'text-white'
                  } ${index === navLinks.length - 1 ? 'mb-0' : 'mb-4'}`}
                  onClick={() => {
                    setActive(nav.title)
                    toggleMobileDropdown(nav.id)
                  }}
                >
                  <Link
                    to={`/${nav.id}`}
                    onClick={() => {
                      if (nav.title !== 'Companies') {
                        setToggle(!toggle)
                      }
                    }}
                    className='flex gap-1 items-center'
                  >
                    {nav.title}
                    {nav.dropdown && (
                      <FaChevronDown
                        className={`ml-1 mt-0.5 transition-all ${
                          dropdown === nav.id ? 'transform rotate-180' : ''
                        }`}
                        size={15}
                        color='white'
                      />
                    )}
                  </Link>
                  {nav.dropdown && mobileDropdown === nav.id && (
                    <ul
                      className='transition-all duration-300'
                      ref={dropdownRef}
                    >
                      {nav.dropdown.map((item) => (
                        <li
                          key={item.id}
                          className='px-4 py-2 cursor-pointer text-white'
                          onClick={() => {
                            setActive(item.title)
                            setToggle(!toggle)
                          }}
                        >
                          <Link to={`/${item.id}`}>{item.title}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  )
}

export default Navbar
