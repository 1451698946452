import React from 'react'

const Clients = ({ clients }) => {
  return (
    <div className='bg-gradient-to-r from-[#FFFFFF] to-cyan-200 flex flex-col items-center justify-center w-full px-4 py-10 md:px-20 md:py-20'>
      <div className='text-center mb-16'>
        <h1 className='text-6xl font-bold text-orange-500 mb-5'>Our Clients</h1>
        <h4 className='text-4xl text-orange-500'>
          Few of Our Valuable Clients
        </h4>
      </div>
      <div className='flex items-center justify-between flex-wrap gap-4 md:gap-24'>
        {clients.map((client) => (
          <img
            key={client.name}
            src={client.url}
            alt={client.name}
            className='w-48 h-48 object-contain mx-auto hover:scale-105 cursor-pointer transition'
          />
        ))}
      </div>
    </div>
  )
}

export default Clients
