import React, { useEffect } from 'react'
import Carousel from '../components/Carousel'
import GroupDetails from '../components/GroupDetails'
import { groupSlides, groupInfo } from '../constant'
import Milestone from '../components/Milestone'
import ChooseUs from '../components/ChooseUs'

const Home = () => {
  useEffect(() => {
    document.title = 'Greentouch Group'
  }, [])
  return (
    <div>
      <Carousel slides={groupSlides} />
      {groupInfo.map((info) => (
        <GroupDetails
          title={info.title}
          detail={info.detail}
          style={info.style}
          framerMotionStyle={info.framerMotionStyle}
          image={info.image}
          img_alt={info.img_alt}
          video={info.video}
        />
      ))}
      <Milestone />
      <ChooseUs />
    </div>
  )
}

export default Home
