import React, { useEffect } from 'react'
import Carousel from '../components/Carousel'
import CompanyDetails from '../components/CompanyDetails'
import { developmentInfo, developmentSlides } from '../constant'
import Products from '../components/Products'
import Projects from '../components/Projects'

const GBL = () => {
  useEffect(() => {
    document.title = 'Greentouch Development Ltd'
  }, [])

  return (
    <div className='snap-proximity snap-y'>
      <div className='snap-center'>
        <Carousel slides={developmentSlides} />
      </div>
      {developmentInfo.map((info) => (
        <div className='snap-center'>
          <CompanyDetails
            title={info.title}
            detail={info.detail}
            style={info.style}
          />
        </div>
      ))}

      {/* <Products /> */}
      {/* <Projects slides={gclProjects} /> */}
    </div>
  )
}

export default GBL
