import './App.css'
import { Route, Routes } from 'react-router-dom'
import Navbar from './components/common/Navbar'
import Home from './screens/Home'
import GCL from './screens/GCL'
import GEL from './screens/GEL'
import Messages from './screens/Messages'
import ContactUs from './screens/ContactUs'
import Footer from './components/common/Footer'
import GSC from './screens/GSC'
import GT from './screens/GT'
import GBL from './screens/GBL'
import Accomplishment from './screens/Accomplishment'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])

    return null
  }
  return (
    <div className='flex flex-col min-h-screen'>
      <Navbar />
      <ScrollToTop />
      <div className='flex-grow'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/corporation' element={<GCL />} />
          <Route path='/exim' element={<GEL />} />
          <Route path='/builders' element={<GBL />} />
          <Route path='/stone-crusher' element={<GSC />} />
          <Route path='/transport' element={<GT />} />
          <Route path='/messages' element={<Messages />} />
          <Route path='/accomplishment' element={<Accomplishment />} />
          <Route path='/contact' element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
