import React from 'react'
import { whychooseus } from '../constant'
import { motion } from 'framer-motion'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ChooseUs = () => {
  return (
    <div className='xl:px-20'>
      <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-[#19AC53] to-orange-400 to-[90%] first-letter text-[40px] md:text-[100px] tracking-wider font-bold text-center w-[320px] md:w-[800px] mx-auto mb-8 md:mb-24'>
        Why Choose Us
      </h1>
      {whychooseus.map((reason, idx) => (
        <div
          className={`flex ${reason.rowStyle} items-center mb-12 md:mb-40 group`}
        >
          <motion.div
            className={`flex items-center ${
              idx % 2 === 0 ? 'justify-start' : 'justify-end'
            } gap-10 md:w-1/2 px-5 md:px-32 mb-5 md:mb-0 group-hover:scale-110 transition-all`}
          >
            <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-[#19AC53] to-orange-400 to-[90%] first-letter text-[60px] md:text-[80px] font-bold text-center w-[70px] md:w-[100px]'>
              {reason.number || <Skeleton />}
            </h1>
            <div className='bg-gradient-to-r from-[#FDF79C] to-[#84CA9C] w-[188px] h-[60px] md:w-[255px] md:h-[81px] text-[22px] md:text-[30px] font-bold flex items-center justify-center rounded-lg shadow-xl'>
              {reason.title || <Skeleton />}
            </div>
          </motion.div>
          <motion.div
            whileInView={{ x: [-80, 0] }}
            transition={{ delay: 0.5 }}
            className={`flex flex-col ${reason.textAlignStyle} md:w-1/2 px-10 md:px-16 lg:px-32 mb-5 md:mb-0 group-hover:scale-110 transition-all`}
          >
            <h1
              className={`${reason.textAlignStyle} font-bold text-[20px] md:text-[28px]`}
            >
              {reason.heading || <Skeleton />}
            </h1>
            <p
              className={`${reason.textAlignStyle} text-[16px] md:text-[20px]`}
            >
              {reason.detail || <Skeleton count={3} />}
            </p>
          </motion.div>
        </div>
      ))}
    </div>
  )
}

export default ChooseUs
