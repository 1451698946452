import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GroupDetails = ({ title, detail, style, image, img_alt, video }) => {
  //   const text =
  //     'At Greentouch Group, we are dedicated to providing innovative and sustainable solutions to the construction and RMG industry in Bangladesh. Our group of companies offers a range of comprehensive products and services, including raw materials supply, transportation solutions, real estate development, and apparel sourcing services. We prioritize quality, customer satisfaction, and environmental responsibility in all that we do, and our team of experts is committed to delivering exceptional results. We embrace new technologies and opportunities to stay ahead of the curve, and we are constantly evolving to meet the changing needs of our industry. As we continue to diversify and expand our business, we create long-term value for our customers, stakeholders, and society shaping a better future for everyone.'

  //   const [displayText, setDisplayText] = useState('')
  //   const [currentIndex, setCurrentIndex] = useState(0)

  //   useEffect(() => {
  //     if (currentIndex < text.length) {
  //       const timeout = setTimeout(() => {
  //         setCurrentIndex((prevIndex) => prevIndex + 1)
  //       }, 10)
  //       return () => clearTimeout(timeout)
  //     }
  //   }, [currentIndex, text.length])

  //   useEffect(() => {
  //     setDisplayText(text.substring(0, currentIndex))
  //   }, [currentIndex, text])

  return (
    <div
      className={`${style} items-center justify-center gap-5 md:gap-14 w-full min-h-screen md:h-screen px-5 py-10 md:py-0 md:px-20`}
    >
      <div className={image ? 'md:w-1/2 px-4' : 'w-full px-4'}>
        <h1 className='text-[40px] md:text-[70px] font-bold text-orange-500 text-center mb-4'>
          {title || <Skeleton />}
        </h1>
        <p className='text-[16px] md:text-[20px] leading-[18px] md:leading-[30px] md:tracking-wide text-justify'>
          {detail || <Skeleton count={5} />}
        </p>
      </div>
      <div
        className={
          image || video
            ? 'w-full md:w-1/2 flex items-center justify-center'
            : 'hidden'
        }
      >
        {image ? (
          <img
            src={image || <Skeleton />}
            alt={img_alt}
            className='w-72 md:w-[800px] rounded-xl'
          />
        ) : (
          <video
            src={video}
            autoPlay
            muted
            loop
            type='video/mp4'
            className='w-72 md:min-w-[500px] lg:min-w-[600px] xl:min-w-[700px] 2xl:min-w-[800px] rounded-xl'
          />
        )}
      </div>
    </div>
  )
}

export default GroupDetails
