import React from 'react'

const Footer = () => {
  return (
    <div className='bg-gradient-to-b from-[#FDF79C]/75 to-[#4FB06B]/75 flex flex-col md:flex-row items-center justify-between px-2 py-2 md:px-20 md:py-4 h-16'>
      <p className='flex items-center text-[12px] md:text-[20px]'>
        Copyright © 2023 Greentouch Group. All Rights Reserved.
      </p>
      <p className='text-[12px] md:text-[20px]'>
        Designed and Developed by{' '}
        <a
          href='https://facebook.com/numan.talukder'
          target='_blank'
          rel='external'
          className='text-green-600 hover:text-orange-600'
        >
          Numan
        </a>
      </p>
    </div>
  )
}

export default Footer
