import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const MessageTemplate = ({
  title,
  detail,
  style,
  image,
  img_alt,
  name,
  designation,
  company,
}) => {
  //   const text =
  //     'At Greentouch Group, we are dedicated to providing innovative and sustainable solutions to the construction and RMG industry in Bangladesh. Our group of companies offers a range of comprehensive products and services, including raw materials supply, transportation solutions, real estate development, and apparel sourcing services. We prioritize quality, customer satisfaction, and environmental responsibility in all that we do, and our team of experts is committed to delivering exceptional results. We embrace new technologies and opportunities to stay ahead of the curve, and we are constantly evolving to meet the changing needs of our industry. As we continue to diversify and expand our business, we create long-term value for our customers, stakeholders, and society shaping a better future for everyone.'

  //   const [displayText, setDisplayText] = useState('')
  //   const [currentIndex, setCurrentIndex] = useState(0)

  //   useEffect(() => {
  //     if (currentIndex < text.length) {
  //       const timeout = setTimeout(() => {
  //         setCurrentIndex((prevIndex) => prevIndex + 1)
  //       }, 10)
  //       return () => clearTimeout(timeout)
  //     }
  //   }, [currentIndex, text.length])

  //   useEffect(() => {
  //     setDisplayText(text.substring(0, currentIndex))
  //   }, [currentIndex, text])

  return (
    <div
      className={`${style} items-center justify-center w-full px-5 py-5 md:py-0 md:px-12 2xl:px-20`}
    >
      <div className={image ? 'md:w-1/2 px-4' : 'w-full px-4'}>
        <h1 className='text-[26px] md:text-[55px] font-bold text-orange-500 text-center mb-4'>
          {title || <Skeleton />}
        </h1>
        <p className='text-[12px] md:text-[18px] 2x:text-[20px] leading-[16px] md:leading-[20px] 2xl:leading-[24px] text-justify'>
          {detail.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <div className='flex flex-col mt-5 text-[12px] md:text-[18px] 2x:text-[20px]'>
          <p>{name || <Skeleton />}</p>
          <p>
            {designation.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
          <p>{company || <Skeleton />}</p>
        </div>
      </div>
      <div
        className={
          image
            ? `h-1/2 md:h-screen md:w-1/2 flex ${
                img_alt != 'adviser' ? 'items-end' : 'items-center'
              } md:items-end justify-center hover:floating-image`
            : 'hidden'
        }
      >
        <img
          src={image || <Skeleton />}
          alt={img_alt}
          className='h-5/6 hover:scale-105 transition-all'
        />
      </div>
    </div>
  )
}

export default MessageTemplate
