import React, { useState, useEffect } from 'react'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import { RxDotFilled } from 'react-icons/rx'

function Carousel({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length
      setCurrentIndex(nextIndex)
    }, 4000)

    return () => clearInterval(interval)
  }, [currentIndex, slides.length])

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }

  return (
    <div className='h-screen w-full m-auto relative group'>
      {slides.map((slide, slideIndex) => (
        <div
          key={slideIndex}
          style={{
            backgroundImage: `url(${slides[currentIndex].url})`,
            // transform: `translateX(${100 * (slideIndex - currentIndex)}%)`, // Slide animation
          }}
          className='w-full h-full bg-center bg-cover duration-500 absolute'
        >
          <div className='w-full h-screen backdrop-brightness-[.85]'>
            <h1 className='text-white text-3xl md:text-5xl font-bold drop-shadow-xl translate-y-40 ml-10 md:ml-20'>
              {slides[currentIndex].name}
            </h1>
          </div>
        </div>
      ))}
      {/* Left Arrow */}
      <div
        className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'
        onClick={prevSlide}
      >
        <BsChevronCompactLeft size={30} />
      </div>
      {/* Right Arrow */}
      <div
        className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'
        onClick={nextSlide}
      >
        <BsChevronCompactRight size={30} />
      </div>
      <div className='hidden group-hover:flex absolute bottom-4 w-full -translate-y-12 mx-auto justify-center py-2 cursor-pointer'>
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className='text-2xl cursor-pointer'
          >
            <RxDotFilled color='white' size={30} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
