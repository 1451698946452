import React, { useState } from 'react'

const Accomplishment = () => {
  const title = [
    'A',
    'c',
    'c',
    'o',
    'm',
    'p',
    'l',
    'i',
    's',
    'm',
    'e',
    'n',
    't',
  ]
  const [mainImage, setMainImage] = useState(
    'https://img.freepik.com/premium-vector/certificate-template-design-a4-size_35986-448.jpg?w=2000'
  )
  const [style, setStyle] = useState('')

  const images = [
    {
      src: mainImage,
      zIndex: 50,
      width: '500px',
      height: '700px',
      translateX: '0px',
      translateY: '350px',
    },
    {
      src: 'https://m.media-amazon.com/images/I/71PMrhqu4+S._AC_SL1500_.jpg',
      zIndex: 40,
      width: '400px',
      height: '600px',
      translateX: '150px',
      translateY: '350px',
    },
    {
      src: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/05848790050897.5e0b5d2662510.jpg',
      zIndex: 40,
      width: '400px',
      height: '600px',
      translateX: '-150px',
      translateY: '350px',
    },
    {
      src: 'https://i.pinimg.com/736x/b9/12/8b/b9128b7913c0791e41b89436fec82868.jpg',
      zIndex: 30,
      width: '350px',
      height: '550px',
      translateX: '300px',
      translateY: '350px',
    },
    {
      src: 'https://djuqbvg97u5zb.cloudfront.net/expressco/images/mastertemplates/323/99_19647_1.jpg',
      zIndex: 30,
      width: '350px',
      height: '550px',
      translateX: '-300px',
      translateY: '350px',
    },
    {
      src: 'https://itsybitsy.in/cdn/shop/products/GF0031_1800x1800.jpg?v=1672745578',
      zIndex: 20,
      width: '300px',
      height: '480px',
      translateX: '420px',
      translateY: '350px',
    },
    {
      src: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/certificate-template-design-6597e994bf8cbd972fe9ff1e7d1fb16d_screen.jpg?ts=1637001193',
      zIndex: 20,
      width: '300px',
      height: '480px',
      translateX: '-420px',
      translateY: '350px',
    },
    {
      src: 'https://www.adelaide.edu.au/brand/sites/default/files/media/editor-images/2021-08/a4-certificate-01.gif',
      zIndex: 10,
      width: '250px',
      height: '420px',
      translateX: '520px',
      translateY: '350px',
    },
    {
      src: 'https://papersizes.online/wp-content/uploads/a4-paper-size-mm.jpg',
      zIndex: 10,
      width: '250px',
      height: '420px',
      translateX: '-520px',
      translateY: '350px',
    },
    // Add more image objects in a similar format
  ]

  const handleImageClick = (newImage) => {
    setMainImage(newImage)
  }

  return (
    <div className='bg-gradient-to-b h-screen md:h-[870px] from-white to-gray-300 from-[60%] md:mt-16'>
      <div className='flex flex-col items-center'>
        <div className='flex text-green-600 text-[40px] md:text-[60px] text-center my-4 font-bold mx-auto'>
          {title.map((char) => (
            <span className='md:hover:-translate-y-2 transition-all'>
              {char}
            </span>
          ))}
        </div>
        {/* <h2 className='text-2xl tracking-tight text-center'>
          Don't just take our word for it...
          <br />
          Read reviews from our customers
        </h2> */}
      </div>

      <div className='hidden relative md:flex items-center justify-center py-5'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt=''
            className={`z-${image.zIndex} absolute max-w-[${image.width}] max-h-[${image.height}] hover:scale-105 transition-all cursor-pointer rounded-lg shadow-lg`}
            style={{
              transform: `translateX(${image.translateX}) translateY(${image.translateY})`,
            }}
            onClick={() => handleImageClick(image.src)}
          />
        ))}
      </div>
      <div className='md:hidden flex justify-center items-center h-screen'>
        <div className='relative w-64'>
          {images.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`Image ${index}`}
              className={`absolute inset-0 w-full transition-all duration-500 transform ${
                index === 0 ? '-translate-y-2' : 'translate-y-2'
              }`}
              style={{ zIndex: images.length - index }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Accomplishment
