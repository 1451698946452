import React, { useEffect } from 'react'
import Carousel from '../components/Carousel'
import CompanyDetails from '../components/CompanyDetails'
import { stoneCrusherSlides, stoneCrusherInfo } from '../constant'
import Products from '../components/Products'

const GSC = () => {
  useEffect(() => {
    document.title = 'Greentouch Design Ltd'
  }, [])

  return (
    <div className='snap-proximity snap-y'>
      <div className='snap-center'>
        <Carousel slides={stoneCrusherSlides} />
      </div>
      {stoneCrusherInfo.map((info) => (
        <div className='snap-center'>
          <CompanyDetails
            title={info.title}
            detail={info.detail}
            style={info.style}
          />
        </div>
      ))}

      {/* <Products /> */}
      {/* <ProjectSlideshow /> */}
    </div>
  )
}

export default GSC
