import React, { useState, useEffect, useRef } from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Projects = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length
      setCurrentIndex(nextIndex)
    }, 5000)

    return () => clearInterval(interval)
  }, [currentIndex, slides.length])

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }

  return (
    <div className='flex flex-col md:flex-row items-center gap-4 md:gap-10 w-full md:h-screen px-8 md:px-20 py-10'>
      <div className='flex flex-col items-center w-full md:w-1/4 text-center'>
        <h2 className='text-3xl font-bold tracking-tight sm:text-4xl'>
          {/* Don't just take our word
          <br /> */}
          A few <span className='text-green-600'>projects</span> we feel proud
          to contributed to
        </h2>

        <div className='flex flex-col justify-start items-start mt-4'>
          <div className='flex items-center'>
            <p className='md:mt-4 text-md md:text-lg text-black mr-1'>
              Project:{' '}
            </p>
            <p className='md:mt-4 text-md md:text-lg text-gray-500'>
              {slides[currentIndex].detail}
            </p>
          </div>
          <div className='flex items-center'>
            <p className='md:mt-4 text-md md:text-lg text-black mr-1'>
              Location:{' '}
            </p>
            <p className='md:mt-4 text-md md:text-lg text-gray-500'>
              {slides[currentIndex].location}
            </p>
          </div>
        </div>

        <div className='mt-8 flex gap-4'>
          <button
            className='prev-button rounded-full border border-green-600 p-3 text-green-600 hover:bg-green-600 hover:text-white'
            onClick={prevSlide}
          >
            <span className='sr-only'>Previous Slide</span>
            <FaArrowLeft />
          </button>

          <button
            className='next-button rounded-full border border-green-600 p-3 text-green-600 hover:bg-green-600 hover:text-white'
            onClick={nextSlide}
          >
            <span className='sr-only'>Next Slide</span>
            <FaArrowRight />
          </button>
        </div>
      </div>

      <div className='relative flex items-center gap-5 w-full md:w-3/4 shadow-lg overflow-x-hidden'>
        <img
          src={slides[currentIndex].img}
          alt={slides[currentIndex].alt}
          className='w-full max-h-[220px] md:max-h-[700px] rounded-lg overflow-hidden transition-all duration-300'
        />
      </div>
    </div>
  )
}

export default Projects
