import React from 'react'

const Products = ({ products }) => {
  return (
    <div className='bg-gradient-to-l from-[#FFFFFF] to-lime-200 w-full py-8 px-10'>
      <h1 className='text-6xl font-bold text-center text-orange-500 my-16'>
        Our Products
      </h1>
      <div className='flex flex-col md:flex-row items-center justify-between flex-wrap gap-16'>
        {products.map((product) => (
          <div className='flex flex-col items-center justify-center p-4 rounded-lg hover:scale-105 cursor-pointer transition'>
            <img
              src={product.img}
              alt='product'
              className='h-[380px] rounded-lg'
            />
            <p className='mt-5 text-xl font-semibold text-center'>
              {product.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Products
