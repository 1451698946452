import React, { useEffect } from 'react'
import Carousel from '../components/Carousel'
import CompanyDetails from '../components/CompanyDetails'
import { transportSlides, transportInfo } from '../constant'
import Products from '../components/Products'
import Projects from '../components/Projects'

const GT = () => {
  useEffect(() => {
    document.title = 'Greentouch Transport'
  }, [])

  return (
    <div className='snap-proximity snap-y'>
      <div className='snap-center'>
        <Carousel slides={transportSlides} />
      </div>
      {transportInfo.map((info) => (
        <div className='snap-center'>
          <CompanyDetails
            title={info.title}
            detail={info.detail}
            style={info.style}
          />
        </div>
      ))}
    </div>
  )
}

export default GT
